import React, { useState } from "react";
import DisposableMain from "./../img/disposableImage.jpg";
import GroceryMain from "./../img/GroceryImage.png";
import SpiritualMain from "./../img/spiritualImage.png";
import PaperDish from "./../img/paperdish.jpg";
import Paperbags from "./../img/paperbags.jpg";
import Paperbowls from "./../img/paperbowls.jpg";
import Paperglass from "./../img/paperglass.jpg";
import Paperstraw from "./../img/paperstraws.jpg";
import Grains from "./../img/grains.jpg";
import Basmatirice from "./../img/basmatirice.jpg";
import Baverages from "./../img/baverages.jpg";
import Ashirwadatta from "./../img/ashirwadatta.jpg";
import Chanadal from "./../img/chanadal.jpg";
import DecoretDish from "./../img/decoratedish.jpg"
import DhupImage from "./../img/dhup.jpg"
import Agarbatti from "./../img/agarbatti.jpg"
import SemiHuskedCoconut from "./../img/semiHuskedCoconut.jpg"

function Products() {
  const [currentPage, setCurrentPage] = useState("disposableItems");

  return (
    <div>
      <div className="d-flex justify-content-center align-items-center pt-3 pb-3 mt-3 main-items">
        <div
          className="product-items"
          onClick={() => setCurrentPage("disposableItems")}
        >
          <img src={DisposableMain} alt="" />
          <div>Disposable Items</div>
        </div>
        <div
          className="product-items"
          onClick={() => setCurrentPage("groceryItems")}
        >
          <img src={GroceryMain} alt="" />
          <div>Grocery Items</div>
        </div>
        <div
          className="product-items"
          onClick={() => setCurrentPage("spiritualItems")}
        >
          <img src={SpiritualMain} alt="" />
          <div>Spiritual Items</div>
        </div>
      </div>
      <div className="products mt-3">
        {currentPage === "disposableItems" ? (
          <>
            <h1 className="text-center mt-5 font-28 text-primary">Disposable Items</h1>
            <div className="mb-5 d-flex justify-content-center">
              <div class="flex-container">
                <div className="sub-products">
                  <img src={PaperDish} alt="" />
                  <h4>Paper Dishes</h4>
                </div>
                <div className="sub-products">
                  <img src={Paperbags} alt="" />
                  <h4>Paper Bags</h4>
                </div>
                <div className="sub-products">
                  <img src={Paperbowls} alt="" />
                  <h4>Paper Bowls</h4>
                </div>
                <div className="sub-products">
                  <img src={Paperglass} alt="" />
                  <h4>Paper Glasses</h4>
                </div>
                <div className="sub-products">
                  <img src={Paperstraw} alt="" />
                  <h4>Paper Straws</h4>
                </div>
              </div>
            </div>
          </>
        ) : currentPage === "groceryItems" ? (
          <>
            <h1 className="text-center mt-5 font-28 text-primary">Grocery Items</h1>
            <div className="mb-5 d-flex justify-content-center">
              <div class="flex-container">
                <div className="sub-products">
                  <img src={Grains} alt="" />
                  <h4>All types of Grains</h4>
                </div>
                <div className="sub-products">
                  <img src={Basmatirice} alt="" />
                  <h4>All types of Rice</h4>
                </div>
                <div className="sub-products">
                  <img src={Baverages} alt="" />
                  <h4>All types of Baverages</h4>
                </div>
                <div className="sub-products">
                  <img src={Ashirwadatta} alt="" />
                  <h4>Ashirwad Atta</h4>
                </div>
                <div className="sub-products">
                  <img src={Chanadal} alt="" />
                  <h4>Chana Dal</h4>
                </div>
                <div className="sub-products">
                  <img src={SemiHuskedCoconut} alt="" />
                  <h4>Semi Husked Coconut</h4>
                </div>
              </div>
            </div>
          </>
        ) : currentPage === "spiritualItems" ? (
          <>
             <>
            <h1 className="text-center mt-5 font-28 text-primary">Spiritual Items</h1>
            <div className="mb-5 d-flex justify-content-center">
              <div class="flex-container">
                <div className="sub-products">
                  <img src={DecoretDish} alt="" />
                  <h4>Decoreted aarti dish</h4>
                </div>
                <div className="sub-products">
                  <img src={DhupImage} alt="" />
                  <h4>Dhup</h4>
                </div>
                <div className="sub-products">
                  <img src={Agarbatti} alt="" />
                  <h4>Agarbatti</h4>
                </div>  
              </div>
            </div>
          </>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Products;
